
/* Grid System
   ================================ 
   Habanero
   http://habanero.digital/

*/

$one_col: (100% - ($gutter * ($cols - 1))) / $cols;
@mixin cols($num) {
	width: ($one_col * $num) + ($gutter * ($num - 1));
}
@mixin offset($off) {
	margin-left: (($one_col * $off) + ($gutter * ($off - 1)) + $gutter);
}
@mixin push($pos) {
	left: ($one_col * $pos) + ($gutter * ($pos - 1)) + $gutter;
}
@mixin sub_cols($num_child, $num_parent) {
	$parent_size: ($one_col * $num_parent) + ($gutter * ($num_parent - 1));
	$child_size: ($one_col * $num_child) + ($gutter * ($num_child - 1));
	margin-left: ($gutter / $parent_size) * 100%;
	width: ($child_size / $parent_size) * 100%;
}
@mixin media($min-width, $max-width) {
	@if $min-width > 0 and $max-width > 0 {
		@media screen and (min-width: ($min-width - 1)+px) and (max-width: ($max-width - 1)+px) {
			@content;
		}
	}
	@if $min-width and $max-width <= 0 {
		@media screen and (min-width: ($min-width - 1)+px) {
			@content;
		}
	}
	@if $max-width and $min-width <= 0 {
		@media screen and (max-width: ($max-width - 1)+px) {
			@content;
		}
	}
}
@mixin media-device($min-width, $max-width) {
	@if $min-width > 0 and $max-width > 0 {
		@media screen and (min-device-width: ($min-width - 1)+px) and (max-device-width: ($max-width - 1)+px) {
			@content;
		}
	}
	@if $min-width and $max-width <= 0 {
		@media screen and (min-device-width: ($min-width - 1)+px) {
			@content;
		}
	}
	@if $max-width and $min-width <= 0 {
		@media screen and (max-device-width: ($max-width - 1)+px) {
			@content;
		}
	}
}
.w100 {
	width:100%;
	position:relative;
	float:left;
}
.clear {
	clear:both;
}
.row {
	width: 100%;
	max-width:$desktop+px;
	position: relative;
	margin:0 auto;
	min-width:728px;
	.col {
		float: left;
		margin-left: $gutter;
	}
	@include media(0, 1250) {
		width: 92.5%;
	}
	@include media(0, $lmobile) {
		width: 92.5%;
	}
	@include media(0, $smobile) {
		width: 90%;
	}

	// Grid
	@for $i from 1 through $cols {
		$k: $cols / $i;
		.d-#{$i} {
			@include cols($i);
			@for $j from 1 through ($i - 1) {
				.d-#{$j} {
					$l: $cols / $j;
					@include sub_cols($j, $i);
					&:first-child {
						margin-left:0;
					}
				}
			}
			&:first-child {
				margin-left:0;
			}
			@if round($k) == $k {
				&:nth-child(#{$k}n+1) {
					margin-left:0;
				}
			}
		}
	}	
	@include media(0, $tablet) {
		max-width:100%;
		@for $i from 1 through $cols {
			$k: $cols / $i;
			.t-#{$i} {
				@include cols($i);
				&:nth-child(n) {
					margin-left:$gutter;
				}
				@for $j from 1 through ($i - 1) {
					.t-#{$j} {
						$l: $cols / $j;
						@include sub_cols($j, $i);
						&:first-child {
							margin-left:0;
						}
					}
				}
				&:first-child {
					margin-left:0;
				}
				@if round($k) == $k {
					&:nth-child(#{$k}n+1) {
						margin-left:0;
					}
				}
			}
		}	
	}
	// @include media(0, $lmobile) {
	// 	@for $i from 1 through $cols {
	// 		$k: $cols / $i;
	// 		.lm-#{$i} {
	// 			@include cols($i);
	// 			&:nth-child(n) {
	// 				margin-left:$gutter;
	// 			}
	// 			@for $j from 1 through ($i - 1) {
	// 				.lm-#{$j} {
	// 					$l: $cols / $j;
	// 					@include sub_cols($j, $i);
	// 					&:first-child {
	// 						margin-left:0;
	// 					}
	// 				}
	// 			}
	// 			&:first-child {
	// 				margin-left:0;
	// 			}
	// 			@if round($k) == $k {
	// 				&:nth-child(#{$k}n+1) {
	// 					margin-left:0;
	// 				}
	// 			}
	// 		}
	// 	}	
	// }
	// @include media(0, $smobile) {
	// 	@for $i from 1 through $cols {
	// 		$k: $cols / $i;
	// 		.sm-#{$i} {
	// 			@include cols($i);
	// 			&:nth-child(n) {
	// 				margin-left:$gutter;
	// 			}
	// 			@for $j from 1 through ($i - 1) {
	// 				.sm-#{$j} {
	// 					$l: $cols / $j;
	// 					@include sub_cols($j, $i);
	// 					&:first-child {
	// 						margin-left:0;
	// 					}
	// 				}
	// 			}
	// 			&:first-child {
	// 				margin-left:0;
	// 			}
	// 			@if round($k) == $k {
	// 				&:nth-child(#{$k}n+1) {
	// 					margin-left:0;
	// 				}
	// 			}
	// 		}
	// 	}	
	// }

	// Offsets
	@for $i from 1 through $cols {
		.d-off-0 {
			margin-left:auto !important;
		}
		.d-off-#{$i}, .d-off-#{$i}:first-child {
			@include offset($i);
		}
	}
	@include media(0, $tablet) {
		.t-off-0 {
			margin-left:auto !important;
		}
		@for $i from 1 through $cols {
			.t-off-#{$i}, .t-off-#{$i}:first-child {
				@include offset($i);
			}
		}
	}
	// @include media(0, $lmobile) {
	// 	.lm-off-0 {
	// 		margin-left:auto !important;
	// 	}
	// 	@for $i from 1 through $cols {
	// 		.lm-off-#{$i}, .lm-off-#{$i}:first-child {
	// 			@include offset($i);
	// 		}
	// 	}
	// }
	// @include media(0, $smobile) {
	// 	.sm-off-0 {
	// 		margin-left:auto !important;
	// 	}
	// 	@for $i from 1 through $cols {
	// 		.sm-off-#{$i}, .sm-off-#{$i}:first-child {
	// 			@include offset($i);
	// 		}
	// 	}
	// }

	// Push
	@for $i from 1 through $cols {
		.d-push-0 {
			left:auto;
		}
		.d-push-#{$i} {
			position: absolute;
			margin-left: 0;
			@include push($i);
		}
	}
	@include media(0, $tablet) {
		.t-push-0 {
			left:auto;
		}
		@for $i from 1 through $cols {
			.t-push-#{$i} {
				position: absolute;
				margin-left: 0;
				@include push($i);
			}
		}
	}
	// @include media(0, $lmobile) {
	// 	.lm-push-0 {
	// 		left:auto;
	// 	}
	// 	@for $i from 1 through $cols {
	// 		.lm-push-#{$i} {
	// 			position: absolute;
	// 			margin-left: 0;
	// 			@include push($i);
	// 		}
	// 	}
	// }
	// @include media(0, $smobile) {
	// 	.sm-push-0 {
	// 		left:auto;
	// 	}
	// 	@for $i from 1 through $cols {
	// 		.sm-push-#{$i} {
	// 			position: absolute;
	// 			margin-left: 0;
	// 			@include push($i);
	// 		}
	// 	}
	// }
}
$list_gutter: ($gutter/2);
.listing {
	text-align: center;
	margin:0;
	padding:0;
	width:(100% + $list_gutter*4);
	margin-left:-$list_gutter*2;
	@include media(0, $tablet) {
		width:(100% + $list_gutter*2);
		margin-left:-$list_gutter;
	}
	@include media(0, $lmobile) {
		width:(100% + $list_gutter*6);
		margin-left:-$list_gutter*3;
	}
	@include media(0, $smobile) {
		width:100%;
		margin-left:0;
	}
	float:left;
	>li, >div {
		list-style: none;
		display:inline-block;
		vertical-align:top;
		margin-left:$list_gutter;
		margin-right:$list_gutter;
		margin-bottom:20px;
	}
	&.fl {
		>li, >div {
			float:left;
			margin-left:0;
		}
	}
	@for $i from 1 through $cols {
		&.d-list-#{$i} {
			>li, >div {
				width:(100-(($list_gutter*4)*($i - 1)))/$i;
				&:nth-child(#{$i}n) {
					margin-left:$list_gutter;
					margin-right:$list_gutter;
				}
			}
		}
		&.d-list-1 {
			>li, >div, >li:nth-child(n), >div:nth-child(n) {
				width:100%;
				margin-right:0;
				margin-left:0;
			}
		}
	}
	@include media(0, $tablet) {
		@for $i from 1 through $cols {
			&.t-list-#{$i} {
				>li, >div {
					width:(100-(($list_gutter*4)*($i - 1)))/$i;
					&:nth-child(n) {
						margin-left:$list_gutter;
						margin-right:$list_gutter;
					}
					&:nth-child(#{$i}n) {
						// margin:0;
					}
				}
			}
			&.t-list-1 {
				>li, >div, >li:nth-child(n), >div:nth-child(n) {
					width:100%;
					margin-right:0;
					margin-left:0;
				}
			}
		}
	}
	// @include media(0, $lmobile) {
	// 	@for $i from 1 through $cols {
	// 		&.lm-list-#{$i} {
	// 			>li, >div {
	// 				width:(100-(($list_gutter*8)*($i - 1)))/$i;
	// 				&:nth-child(n) {
	// 					margin-left:$list_gutter;
	// 					margin-right:$list_gutter;
	// 				}
	// 				&:nth-child(#{$i}n) {
	// 					// margin:0;
	// 				}
	// 			}
	// 		}
	// 		&.lm-list-1 {
	// 			>li, >div, >li:nth-child(n), >div:nth-child(n) {
	// 				width:100%;
	// 				margin-right:0;
	// 				margin-left:0;
	// 			}
	// 		}
	// 	}
	// }
	// @include media(0, $smobile) {
	// 	@for $i from 1 through $cols {
	// 		&.sm-list-#{$i} {
	// 			>li, >div {
	// 				width:(100-(($list_gutter*8)*($i - 1)))/$i;
	// 				&:nth-child(n) {
	// 					margin-left:$list_gutter*2;
	// 					margin-right:$list_gutter*2;
	// 				}
	// 				&:nth-child(#{$i}n) {
	// 					margin-left:0;
	// 					margin-right:0;
	// 				}
	// 			}
	// 		}
	// 		&.sm-list-1 {
	// 			>li, >div, >li:nth-child(n), >div:nth-child(n) {
	// 				width:100%;
	// 				margin-left:0;
	// 				margin-right:0;
	// 			}
	// 		}
	// 	}
	// }
}
