
/* Form Styles
   ================================ 
   Habanero
   http://habanero.digital/

*/

$form-pad:7px;
$form-size:95%;
$form-margin:5px;

.tags {
	
	-webkit-appearance: none !important;
	border:1px solid #ccc !important;
	padding: 10px 10px !important;
	input {
		font-size: 1em !important;
		font-family:$font !important;
	}
}

.form-style, .tags {
	font-family:$font;
	border:1px solid #ccc;
	color:$b;
	background:$w;
	border-radius:$radius;
	width:100%;
	font-size:$form-size;
	padding:$form-pad 15px;
	margin:$form-margin 0;
	&.wpcf7-not-valid {
		border:1px solid red;
	}
}
.ajax-loader {
	display: none !important;
}
span.wpcf7-not-valid-tip {
	color:$w !important;
	background: red;
	padding:10px 15px;
	font-size:15px !important;
	line-height:24px;
	margin-top:-10px;
	font-weight: bold;
}
div.wpcf7-response-output {
	border:0;
	margin:25px 0 !important;
	padding:10px 15px !important;
	font-size:15px;
	line-height:24px;
	letter-spacing:0.05em;
	font-weight: 500;
	&.wpcf7-validation-errors {
		background: red;
		color:$w;
		border:0;
	}
	&.wpcf7-mail-sent-ok {
		border: 0 !important;
		background: green;
		color:$w !important;
	}
}
input[type=submit], button {
	@extend .button-style;
}
input[type=submit] {
	margin:$form-margin 0;
}
label {
	margin:$form-margin 0 0 0;
	@extend p;
}
input[type=file] {
	font-size: 0.8em;
}

input[type=text], input[type=phone], input[type=email], input[type=password], input[type=number], input[type=date], .tags {
	@extend p;
	@extend .form-style;
	@include box-sizing;
	outline: none !important;
	box-shadow: none !important;
}

input[disabled=disabled] {
	opacity: 0.3;
}

input[type=checkbox] {
	width:22px;
	height:22px;
	appearance:none;
	background-color: $w;
	border-radius:4px;
	border:1px solid #ccc;
	display: inline-block;
	position: relative;
	
	
	&:checked {
		background: $mblue;
		border-color:$mblue;
		&:after {
			font-family: FontAwesome;
			content:"\f00c";
			color:$w;
			font-size:18px;
			top:1px;
			left:1px;
			z-index:2;
			position: absolute;
			// display: block;
		}
	}
}
textarea {
	min-height:250px;
	@extend p;
	@extend .form-style;
	@include box-sizing;
}
.custom-select {
	appearance:none;
	-webkit-appearance:none;
	background:$w url('../images/select.png') no-repeat center right ($form-pad+2px);
	padding:($form-pad+2px) 35px ($form-pad+2px) ($form-pad+2px);
	&:focus {
		background:$w url('../images/select_active.png') no-repeat center right ($form-pad+2px);
	}
}
select {
	@extend .form-style;
	@extend .custom-select;
}

ul.errors li {
	margin-bottom: 8px;
	color: $red;
	font-size: 0.82em;
}
