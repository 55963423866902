
// Grid variables
// ---------------------------------------
$cols: 12;
$gutter: 2%;
$desktop: 1140;
$tablet: 960;
$lmobile: 767;
$smobile: 460;

// Custom Fonts 
// ---------------------------------------
$font: Open Sans, Helvetica, Arial, sans-serif;

// Social Media colours
// ---------------------------------------
$fb: #3b5998;
$tw: #00aced;
$gp: #dd4b39;
$yt: #bb0000;
$ln: #007bb6;
$pnt: #cb2027;

// Effects
// ---------------------------------------
$time: 0.3s;
$radius: 5px;

// Colour
// ---------------------------------------
$b: #000;
$w: #fff;
$lgrey: #F2F2F2;
$dblue: #1C497A;
$mblue: #1677CB;
$mgrey: #97999F;
$dgrey: #373A3C;
$green: #5CB85C;
$red: #CC0000;
$green: #5CB85C;
$fade: #F0F6FC;
$shadow: 0px 0px 5px rgba(0,0,0,0.2);