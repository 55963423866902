
/* Mulholland
   ================================ 
*/

/* Normalize
   ================================ */
@import "normalize";

/* Variables & Mixins
   ================================ */
@import "variables";
@import "mixins";

/* Font Awesome
   ================================ */
@import "fontawesome";

/* Grid System
   ================================ */
@import "grid";

/* Form Styles
   ================================ */
@import "forms";

/* Sliders
   ================================ */
@import "slick";

/* Node
   ================================ */
@import "../../node_modules/ng-dialog/css/ngDialog.min";
@import "../../node_modules/ng-dialog/css/ngDialog-theme-default.min";
@import "../../node_modules/angular-flash-alert/dist/angular-flash.min";
@import "../../node_modules/ng-tags-input/build/ng-tags-input.min";

.alert .row div {
	margin: 0 26px;
}

.login-state {
	display: none;
	
	&.logged-in {
		display: block;
	}
}

#gallery {
	
	height: 100%;
	overflow: hidden;
	
	ul {
		height: 50px;

		li {
			float: left;
			margin-right: 30px;
			
			a {
				font-size: 0.9em;
				
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	
	.count {
		font-size: 0.9em;
		color: #333333;
	}
	
	.image {
		background-size: cover;
		height: 100%;
	}
}

#sites select {
    width: 400px;
    float: right;
    margin-top: 62px;
}

#sites input.closed, label.closed {
    float: right;
}

#sites input.closed {
    margin-top: 72px;
    margin-right: 10px;
}

#sites label.closed {
	margin-top: 70px;
	margin-right: 20px;
}

#sites ul.options {
	list-style-type: none;
	
	li {
		margin-top: 56px;
		float: left;
		margin-right: 20px;
		
		&:before {
			content: none;
		}
		
		a {
			text-decoration: none;
			font-size: 12.8px;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.plot-stage-thumb {
	width:85px;
	height:85px;
	display: block !important;
	img {
		max-width:100%;
		margin-top: 10px;
		display: block;
	}
}

.clear {
	clear: both;
}

.ngdialog-theme-default {
	.ngdialog-close {
		margin: 18px;
	}
	
	.ngdialog-content {
		background: white !important;
	}
}

form.modal {
	h1 {
		margin: 0 0 10px 0;
		font-weight: 400;
		font-size: 1.5em;
	}
	
	p {
		color: #97999F;
		font-size: 0.82em;
		width: 80%;
	}
	
	label {
		color: #373A3C;
		width: 100%;
		display: block;
	}
	
	hr {
		border: 0;
	    height: 1px;
	    background: #CCCCCC;
	    margin: 10px -18px;
	}
	
	button {
		float: right;
		margin-top: 8px;
	}
	
	input[type=text], input[type=password], input[type=email], textarea, input[type=number], input[type=date], .tags {
		width: 100%;
	}
}

form#login {
    width: 400px;
    height: 250px;
    position: absolute;
    top:-170px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    
    div.logo {
	    text-align: center;
	    padding-bottom: 40px;
    }
    
    button {
	    margin-top: 8px;
    }
}

/* Helper Styles
   ================================ */
html {
	width:100%;
	height:100%;
	position: relative;
	background: $lgrey;
	body {
		-webkit-font-smoothing: antialiased; 
		font-family:$font;
		position: relative;
		// max-width:2200px;
		margin:0 auto;
		background: $lgrey;
		min-height:100vh;
	}
}
ul {
	margin: 0;
	padding: 0;
	> li {
		margin: 0;
		padding: 0;
		list-style: none;
		ol, ul {
			margin-bottom:0.5rem;
		}
	}
}
ol {
	> li {
		list-style:decimal;
		ol, ul {
			margin-bottom:0.5rem;
		}
	}
}
ul.tabs-content > li {
    display: none;
}
ul.tabs-content > li.active {
    display: block;
}
ul.tabs-content, ul.tabs, .content .slider ul, .slider ul {
	margin:0;
	padding:0;
	> li {
		list-style:none;
		&:before {
			display:none;
		}
	}
}
a {
	text-decoration:none;
	cursor:pointer;
	color:$mblue;
	&:hover {
		text-decoration: underline;
		color: $dgrey;
	}
}
div.svg {
	display:none;
}
@for $i from 1 through 6 {
	h#{$i} {
		font-size:220 - ($i*10)+%;
		line-height:140%;
		margin-bottom:0.75em;
		margin-top:1.5em;
		// @include media(0, $lmobile) {
		// 	font-size:34 - ($i*3)+px;
		// 	line-height:38 - ($i*3)+px;
		// }
	}
}
.button-style {
	position: relative;
	text-align: center;
	border:0;
	padding:9px 16px;
	background:$mblue;
	color:$w;
	border-radius:$radius;
	text-decoration: none !important;
	&.small {
		font-size:80%;
		padding:6px 8px;
		i {
			display: inline-block;
			margin-right: 2px;
			font-size:120%;
		}
	}
	&.green {
		background: $green;
	}
	&.red {
		background: $red;
	}	
	&:hover {
		text-decoration: underline !important;
		color:$w;
	}
}
.center {
	position: absolute;
	width:100%;
	top:50%;
	left:0;
	transform:translateY(-52.5%);
}
.center-section {	
	position: relative;
	.row {
		z-index:3;
		height:100%;
	}
	.col {
		position: relative;
		height:100%;
	}
}
$pagination-default:none;
$pagination-inactive-default:$w;
.pagination {
	text-align: center;
	margin-top:40px;
	margin-bottom: -10px;
	.next, .prev {
		display: none;
	}
	span, a {
		display: inline-block;
		width:31px;
		padding:9px 5px;
		font-size:90%;
		background: $w;
		// border-radius:$radius;
		// color:$w;
		// font-weight: bold;
		// margin:0 3px;
		// margin-bottom: 12px;
		&:hover {

		}
	}
	ul {
		float: right;
		li {
			box-shadow: none;
			float:left;
			border:1px solid #ccc;
			margin-right: -1px;
			margin-bottom: -1px;
			&.active {
				span, a {
					background: $mblue;
					color:$w;
				}
			}
		}
	}
	a {
		background: $pagination-default;
	}
	span {
		background: $pagination-inactive-default;
		color:$mgrey;
		&.dots {
			background: none;
			padding-left:0;
			padding-right:0;
			color:$b;
		}
	}
}

/* Default Styles
   ================================ */
.cta {
	@extend .button-style;
}
.breadcrumbs {
	// margin-top:-20px;
	margin-bottom: 40px;
	.pagination {
		margin-top: -22px;
	}
	> ul {
		margin-left:16px;
		li {
			font-size:110%;
			display: inline-block;
			// margin-right: 10px;
			margin: 10px 11px;
			// font-size:95%;
			color:$mgrey;
			position: relative;
			a {
				// font-weight: bold;
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
				&:after {
					display: none;
				}
			}
			&:after {
				content:"/";
				font-size:120%;
				position: absolute;
				right:-17px;
				margin-top:-14px;
				top:50%;
			}
		}
	}
}
.title {
	margin-top:-20px;
	margin-bottom: 50px;
	h3 {
		margin: 0 16px;
		font-size:120%;
		color:$mgrey;
		font-weight: normal;
	}
}
header {
	background: $dblue;
	color:$w;
	.main {
		padding:20px 0;
		.logo {
			display: inline-block;
			font-weight: bold;
			vertical-align: middle;
			margin: 5px 20px;
			@include media(0, $tablet) {
				margin: 5px 10px;
			}
		}
		div.options {
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;
			@include media(0, $tablet) {
				margin-left: 10px;
			}
			a {
				@extend .button-style;
				@extend .small;
			}
		}
		.search-holder {
			width:320px;
			// float:right;
			position: absolute;
			top:50%;
			margin-top:-21px;
			right:0;
			@include media(0, $tablet) {
				width:200px;
			}
		}
	}
	.navigation {
		background: $mblue;
		font-size:90%;
		ul {
			li {
				float:left;
				a {
					padding:20px 16px;
					display: block;
					color:$w;
					text-transform: uppercase;
				}
				&.active {
					a {
						background: $dblue;
					}
				}
			}
		}
	}
}
p {
	line-height:160%;
}
#dashboard {
	
	div.alpha-out {
		opacity: 0.5;
	}
	
	li.resolved, li.approved, li.not-approved {
		font-size: 12.8px;
	}
	
	li.resolved {
		color: #1677CB;
	}
	
	li.approved {
		color: $green;
		
		&:before {
			content:url('/images/approved_icon.png');
			position:relative;
			left:-5px;
			top:0px;
			width: 12px;
			height: 9px;
		}
	}
	
	li.not-approved {
		color: $red;
		
		&:before {
			content:url('/images/not_approved_icon.png');
			position:relative;
			left:-5px;
			top:3px;
			width: 16px;
			height: 16px;
		}
	}
	
	// margin-top:20px;
	$pad:15px;
	h2 {
		text-align: center;
		color:$mgrey;
		font-size:85%;
		text-transform: uppercase;
		margin:0;
		padding-bottom:20px;
		border-bottom:1px solid #ccc;
		margin-bottom: 20px;
	}
	p.posted {
		margin-bottom: 0;
	}
	div.more, ul.options {
		background: $fade;
		display: block;
		margin-top: 0;
	}
	.pad {
		padding:$pad;
		position: relative;
		background: $w;
		&.info {
			display: none;
			padding-top:0;
			p {
				font-size:75%;
				margin-top: 10px;
				&.posted {
					font-size:70%;
					color:$mgrey;
				}
			}
			h6 {
				padding-top:10px;
				border-top:1px solid #ccc;
				margin-bottom:5px;
				font-size:70%;
				color:$mgrey;
				text-transform: uppercase;
			}
		}
		&.has-thumb {
			padding-right:115px;
			min-height:$pad;
			.thumb {
				position: absolute;
				top:$pad;
				right:$pad;
			}
		}
	}
	div.more {
		// display: none;
		min-height:38px;
		display: block;
		position: relative;
		.more-toggle {
			display: block;
			position: absolute;
			width:100%;
			height:100%;
			// display: none;
			i {
				font-size:16px;
				color:$mgrey;
				position: absolute;
				top:50%;
				left:50%;
				margin-left:-8px;
				margin-top:-11px;
			}
		}
	}
	ul.options {
		text-align: right;
		padding:12px 15px;
		display: none;
		padding-bottom: 9px;
		li {
			display: inline-block;
			vertical-align: middle;
			margin-left: 6px;
			margin-bottom: 6px;
			a {
				display: inline-block;
			}
		}
	}
	ul.list {
		> li {
			div.active-toggle.active {
				.pad.info {
					display: block;
				}
				div.more {
					a.more-toggle {
						display: none;
					}
					ul.options {
						display: block;
					}
				}
			}
			box-shadow:$shadow;
			margin-bottom: 15px;
			.thumb {
				width:85px;
				height:85px;
				img {
					max-width:100%;
					display: block;
				}
			}
			h4 {
				font-size:90%;
				font-weight:bold;
				color:$dgrey;
			}
			ul.links {
				font-size:15px;
				li {
					box-shadow:none;
					margin-bottom: 5px;
					font-size:90%;
					&:first-child {
						font-size:100%;
						span.longer {
							font-size:90%;
						}
					}
					a {
						text-decoration: underline;
					}
				}
			}
			h4, h6 {
				margin-top:0;
			}
		}
	}
}
ul.dropdown {
	border:1px solid #ccc;
	position: absolute;
	width:150px;
	background:$lgrey;
	font-size:90%;
	border-radius:$radius;
	z-index:2;
	box-shadow:$shadow;
	&.active {
		display: block;
	}
	li {
		a {
			color:$dgrey;
			display: block;
			padding:6px 10px;
			cursor:pointer;
			&.active {
				font-weight: bold;
			}
		}
		&:first-child {
			a {
				padding-top:8px;
				border-top-left-radius:$radius;
				border-top-right-radius:$radius;
			}
		}
		&:last-child {
			a {
				padding-bottom:10px;
				border-bottom-left-radius:$radius;
				border-bottom-right-radius:$radius;
			}
		}
	}
}
.search-holder {
	position: relative;
	padding-right:124px;
	input[type=text] {
		border-top-right-radius:0;
		border-bottom-right-radius:0;
		display: none;
		margin: 0;
		&.active {
			display: block;
		}
	}
	input[type=submit] {
		margin:0;
		border-radius:0;
		right:42px;
		top:0;
		position: absolute;
	}
	#search_options_toggle {
		border-left:1px solid rgba(255,255,255,0.5);
		margin-left:-4px;
		border-top-left-radius:0;
		border-bottom-left-radius:0;
		position: absolute;
		top:0;
		right:0;
		&:hover {
			text-decoration: none;
		}
		.fa-caret-up {
			display: none;
		}
		&.active {
			.fa-caret-down {
				display: none;
			}
			.fa-caret-up {
				display: block;
			}
		}
	}
	ul.dropdown {
		right:0;
		top:102.5%;
		display: none;
		&.active {
			display: block;
		}
	}
}
.content {
	ul, ol {
		padding-left:50px;
		li {
			@extend p;
			margin-bottom:5px;
		}
	}
	img {
		max-width:100%;
	}
	ul {
		li {
			list-style:none;
			position: relative;
			&:before {
				content:"";
				display:block;
				width:5px;
				height:5px;
				border-radius:100%;
				background:$b;
				position: absolute;
				left:-17px;
				top:9px;			
			}
		}
	}
	blockquote {
		p {
			
		}
	}
}
section {
	padding-bottom: 100px;
	margin-top: 20px;
}
#sites {
	// margin-top:50px;
	.title {
		margin-bottom: 30px;
	}
}
.overlay {
	background: rgba(0,0,0,0.8);
	position: fixed;
	cursor:pointer;
	display: none;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:5;
	&.active {
		display: block;
	}
}
.large-image {
	$img-w:630px;
	$img-h:630px;
	display: none;
	width:$img-w;
	height:$img-h;
	position: fixed;
	top:50%;
	z-index:10;
	left:50%;
	margin-left:-($img-w/2);
	margin-top:-($img-h/2);
	&.active {
		display: block;
	}
	img {
		box-shadow:0px 0px 20px rgba(0,0,0,0.4);
		max-width:100%;
		height:auto;
	}
	a.close {
		display: block;
		color:$w;
		font-size:150%;
		padding-bottom:15px;
		text-align: right;
		margin-top:-50px;
	}
}
.table {
	background: $w;
	box-shadow: $shadow;
	// min-height:700px;
	$pad:18px;
	.thumb {
		width:100px;
		height:100px;
		display: block;
		margin-top:15px;
		
		img {
			max-width:100%;
			display: block;
		}		
	}
	.summary {
		margin-top:15px;
		padding-top:15px;
		border-top:1px solid #ccc;
		
		&.no-border {
			border: 0;
			margin: 0;
			padding: 0;
		}
		
		.thumb {
			margin-top: 5px;
			float:left;
			margin-right:20px;
			margin-bottom:20px;
			img {
				max-width:100%;
			}
		}
	}
	a.cta {
		display: inline-block;
	}
	.pad {
		position: relative;
		padding:$pad 30px;
		min-height:40px;
		&.head {
			padding-bottom: 0;
		}
		&.foot {
			// padding-top: 0;
			padding-bottom: 30px;
		}
	}
	.paged {
		position: absolute;
		top:$pad;
		right:30px;
	}
	table {
		width:100%;
		color:$dgrey;
		td {
			padding:14px 15px;
			a {
				text-decoration: underline;
			}
			&:first-child {
				padding-left:30px;
			}
			&:last-child {
				padding-right:30px;
			}
			ul, ol {
				padding-left:30px;
				li {
					@extend p;
					margin-bottom:5px;
				}
			}
			ul {
				li {
					list-style:none;
					position: relative;
					&:before {
						content:"";
						display:block;
						width:4px;
						height:4px;
						border-radius:100%;
						background:$b;
						position: absolute;
						left:-17px;
						top:10px;			
					}
				}
			}
		}
		thead {
			select {
				width:295px;
				float:right;
				font-weight: normal;
			}
			td {
				font-weight: bold;
				height:40px;
				color:#333;
				padding-top:6px;
				padding-bottom:6px;
				cursor: pointer;
				vertical-align: middle;
				i {
					color:$mblue;
					display: inline-block;
					margin-left: 4px;
				}
			}
		}
		tbody {
			td {
				font-size:90%;
				vertical-align: top;
			}
			p {
				margin:0;
				&.small {
					color:$mgrey;
					font-size:90%;
					margin-bottom: 5px;
					&.black {
						color:$b;
					}
				}
			}
			tr {
				background: $fade;
				border-top:1px solid rgba(0,0,0,0.05);
				border-bottom:1px solid rgba(0,0,0,0.05);
				&:nth-child(2n) {
					background: $w;
				}
				&:last-child {
					border-bottom:0;
				}
			}
		}
		&.last-cell-align-right {
			tr {
				td:last-child {
					text-align: right;
				}
			}
		}
		.checkbox {
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			input[type=checkbox] {
				display: inline-block;
				vertical-align:middle;
				margin-right: 7px;
			}
			label {
				cursor:pointer;
			}
		}
	}
}
ul.tabs {
	display: block;
	border-bottom:1px solid rgba(0,0,0,0.15);
	@include media(0, 1240) {
		padding-bottom:10px;
		border:0;
	}
	li {
		display: inline-block;
		margin-bottom: -1px;
		font-size:95%;
		margin-right: -1px;
		a {
			background: $w;
			border:1px solid rgba(0,0,0,0.15);
			padding:11px 14px;
			display: block;
			border-top-left-radius:$radius;
			border-top-right-radius:$radius;
			&.active {
				border-bottom:0;
				padding-bottom:12px;
				color:$dgrey;
			}
		}
		@include media(0, 1240) {
			margin:0 0 5px 2px;
			a {
				border-radius:$radius;
				padding:11px 10px;
				&.active {
					padding-bottom:11px;
					border-bottom:1px solid rgba(0,0,0,0.15);
				}
			}
		}
	}
}
.status {
	display: inline-block;
	vertical-align: middle;
	padding:2px 8px 2px 8px;
	font-weight: bold;
	font-size:95%;
	background: $mblue;
	color:$w;
	border-radius:$radius;
	&.green {
		background: $green;
	}
	// vertical-align: middle;
	@include media(0, 1150) {
		margin-bottom: 8px;
	}
}
.paged {
	.pagination {
		margin: 0;
		// margin-bottom: 5px;
	}
}
.users {
	li {
		border:1px solid #ccc;
		margin-bottom: -1px;
		position: relative;
		padding:8px 20px;
		padding-right:420px;
		&:first-child {
			border-top-left-radius:$radius;
			border-top-right-radius:$radius;
		}
		&:last-child {
			border-bottom-left-radius:$radius;
			border-bottom-right-radius:$radius;
		}
		h6 {
			margin-top: 0;
			margin-bottom: 2px;
		}
		p {
			margin: 0;
		}
		.contact {
			position: absolute;
			top:50%;
			margin-top:-10px;
			font-size:90%;
			right:20px;
		}
	}
}
.overview {
	color:#333;
	background: $w;
	box-shadow:$shadow;
	.head {
		padding:20px 30px 10px 30px;
		h3 {
			display: inline-block;
			margin:0;
		}
		a {
			font-size:90%;
			float:right;
			text-decoration: underline;
		}
	}
	.pad {
		padding:0px 30px 30px 30px;
	}
	.left {
		width:33%;
		float:left;
	}
	.right {
		width:66%;
		float:right;
		margin-top: 42px;
		a {
			text-decoration: underline;
		}
	}
	@include media(0, 1000) {
		.left, .right {
			display: block;
			float:none;
			width:auto;
		}
		.right {
			margin-top: 30px;
		}
	}
	p {
		font-size:90%;
	}
	h3 {
		font-size:100%;
	}
	h6 {
		// padding-top:10px;
		// border-top:1px solid #ccc;
		// margin-bottom:5px;
		font-size:70%;
		color:$mgrey;
		text-transform: uppercase;
	}
	address {
		@extend p;
		margin:1em 0;
		font-style:normal;
	}
}
.plot {
	color: #333;
	background: $w;
	box-shadow:$shadow;
	a.edit {
		position: absolute;
		left:30px;
		bottom:30px;
		font-size:90%;
	}
	a.signoff {
		margin-top: 5px;
		display: block;
		float: left;
		
		&.disabled {
			background: gray;
		    text-decoration: none !important;
		    cursor: default;
		}
	}
	h3 {
		font-size:110%;
		margin: 0;
		&.plot-name {
			display: block;
			vertical-align: middle;
		}
	}
	p {
		font-size: 0.8em;
	}
	.checkbox {
		font-size:90%;
		display: inline-block;
		vertical-align: middle;
		margin: 20px 0 0 0;
		input {
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
		}
	}
	ul.details {
		p {
			font-size:90%;
			margin-top: 3px;
			margin-bottom: 0;
		}
		h6 {
			margin: 0;
		}
		ul.sizes {
			li {
				display: inline-block;
				span, p {
					display: inline-block;
					vertical-align: bottom;
				}	
				span {
					font-size:80%;
					color:$mgrey;
					margin-bottom: 2px;
				}
				p {
					// margin: 0;
				}
			}
		}
		> li {
			background: $w;
			border:1px solid #ccc;
			margin-bottom:-1px;
			padding:10px 20px 12px 20px;
			padding-right:280px;
			position: relative;
			p.date {
				font-size:80%;
				position: absolute;
				top:30px;
				right:20px;
				margin: 0;
			}
			&:first-child {
				border-top-left-radius:$radius;
				border-top-right-radius:$radius;
				@include media(0, $tablet) {
					border-radius:0;
				}
			}
			&:last-child {
				border-bottom-left-radius:$radius;
				border-bottom-right-radius:$radius;
				padding-bottom:20px;
				@include media(0, $tablet) {
					border-radius:0;
				}
			}
		}
	}
	h6 {
		// padding-top:10px;
		// border-top:1px solid #ccc;
		// margin-bottom:5px;
		font-size:70%;
		color:$mgrey;
		text-transform: uppercase;
	}
	.thumb {
		width:100px;
		height:100px;
		margin-top: 10px;
		img {
			max-width:100%;
			height:auto;
		}
	}
	.left {
		float:left;
		width:26%;
		.pad {
			padding:30px;
		}
		@include media(0, $tablet) {
			width:33%;
		}
	}
	.right {
		width:73%;
		float: right;
		background: $fade;
		.pad {
			padding:40px 8%;
		}
		@include media(0, $tablet) {
			width:66%;
			.pad {
				padding:0;
			}
		}
	}
	a {
		text-decoration: underline;
	}
}
@include media(0, $desktop) {

}
@include media(0, $tablet) {

}