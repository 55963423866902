
// SCSS mixins
// ---------------------------------------
@mixin bg($image, $color, $repeat, $left, $top) {
  $image:none !default;
  background-image:$image;
  background-color:$color;
  background-repeat:$repeat;
  background-position:$left $top;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-shadow($shadows...) {
  -moz-box-shadow: $shadows;
  -webkit-box-shadow: $shadows;
  box-shadow: $shadows;
}
@mixin global-shadow {
  -moz-box-shadow: $global-shadow-style;
  -webkit-box-shadow: $global-shadow-style;
  box-shadow: $global-shadow-style;
}
@mixin box-sizing {
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box; 
  box-sizing: border-box; 
}
@mixin circle($diameter) {
  $circ-radius: $diameter/2;
  height: $diameter;
  width: $diameter;
  -moz-border-radius: $circ-radius;
  border-radius: $circ-radius;
}
@mixin rotate($angle) {
  -webkit-transform: rotate($angle);  
  -moz-transform: rotate($angle); 
  -ms-transform: rotate($angle);  
  -o-transform: rotate($angle);  
  transform: rotate($angle);  
}
@mixin text-columns($colnumber,$gutter) {
  -webkit-column-count: $colnumber;  
  -webkit-column-gap: $gutter; 
  -moz-column-count: $colnumber;     
  -moz-column-gap: $gutter; 
  column-count: $colnumber;          
  column-gap: $gutter; 
}
@mixin transform-translate($value...) {
  -webkit-transform: translate3d($value); 
  -moz-transform: translate3d($value); 
  -ms-transform: translate3d($value); 
  -o-transform: translate3d($value);  
  transform: translate3d($value); 
}
@mixin transform-scale($scale) {
  -webkit-transform: scale($scale); 
  -moz-transform: scale($scale); 
  -ms-transform: scale($scale); 
  -o-transform: scale($scale);  
  transform: scale($scale); 
}
@mixin transition($what,$time) {
  transition: $what $time ease-in-out;
  -ms-transition: $what $time ease-in-out;
  -o-transition: $what $time ease-in-out;
  -webkit-transition: $what $time ease-in-out;
  -moz-transition: $what $time ease-in-out;
}
@mixin transition-all($time) {
  transition: all $time ease-in-out;
  -ms-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  -webkit-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
}
@mixin transform-scale($x, $y) {
  -ms-transform: scale($x,$y);
  -webkit-transform: scale($x,$y);
  -moz-transform: scale($x,$y);
  transform: scale($x,$y);
}
@mixin animation($animationname, $time, $timingfunction, $delay, $count) {
  animation:$animationname $time $timingfunction $delay $count;
  -moz-animation:$animationname $time $timingfunction $delay $count;
  -webkit-animation:$animationname $time $timingfunction $delay $count;
}